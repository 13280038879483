import {
  Grid,
  Button,
  Container,
  Box,
  Card,
  Stack,
  CardContent,
  Typography,
  Divider,
  useMediaQuery,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { UseShoppingCart } from "../context/ShoppingCartContext";
import { formatCurrency } from "../utilities/formatCurrency";
import Paper from "@mui/material/Paper";
import { Delete } from "@mui/icons-material";
// import useNoWaAdmin from "../services/queries/useNoWaAdmin";

export const Cart = () => {
  const { cartItems, handleDeleteCart } = UseShoppingCart();
  // const { data: noWaAdmin } = useNoWaAdmin();
  const theme = useTheme();
  const isPhonescreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleRedirectWa = () => {
    window.open(
      `https://api.whatsapp.com/send?phone=628112933188&text=Hallo,%20saya%20ingin%20order%3A%0A%0A${cartItems.map(
        (value) => `${"-"}%20${value.name}%20(x${value.quantity})%0A`
      )}`,
      "_blank"
    );
  };

  return (
    <Box
      sx={{
        height:
          cartItems.length === 0
            ? isPhonescreen
              ? "calc(100vh - 112px)"
              : "calc(100vh - 128px)"
            : "100vh",
      }}
    >
      <Container maxWidth="lg">
        <Stack paddingY={4}>
          {cartItems.length === 0 ? (
            <Stack paddingY={3} alignItems="center">
              <Card
                variant="outlined"
                sx={{ width: "100%", maxWidth: 600, borderRadius: "9px" }}
              >
                <CardContent sx={{ padding: 0 }}>
                  <Typography variant="h6" fontWeight="bold" padding={2}>
                    Keranjang
                  </Typography>
                  <Divider />
                  <Typography padding={2} textAlign="center">
                    Kosong...
                  </Typography>
                  <Divider />
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    paddingX={2}
                    paddingTop={2}
                  >
                    <Button variant="outlined" href="/produk">
                      Lihat Produk
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Stack>
          ) : (
            <Grid
              container
              justifyContent="center"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid item xs={12} sm={12} lg={8}>
                <TableContainer
                  component={Paper}
                  variant="outlined"
                  sx={{
                    borderRadius: 1,
                    backgroundColor: "#fff",
                    border: "1px solid rgba(0,0,0,0.12)",
                    padding: 2,
                    width: "100%",
                  }}
                >
                  <Typography variant="h6" fontWeight="bold" paddingBottom={2}>
                    Keranjang
                  </Typography>
                  <Divider />
                  <Table size="small" aria-label="spanning table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          colSpan={2}
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          Info Produk
                        </TableCell>
                        <TableCell
                          colSpan={2}
                          sx={{
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          Jumlah
                        </TableCell>
                        <TableCell
                          colSpan={2}
                          sx={{
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          Harga
                        </TableCell>
                        <TableCell
                          colSpan={2}
                          sx={{
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          Aksi
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {cartItems.map((value) => (
                        <TableRow>
                          <TableCell colSpan={2}>
                            <Stack>
                              <Link
                                variant="body2"
                                href={`/produk/${value.id}`}
                                underline="hover"
                                color="#ff6f6f"
                              >
                                {value.name}
                              </Link>
                              <Typography variant="caption" color="#747474">
                                {formatCurrency(Number(value.price))}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell colSpan={2} sx={{ textAlign: "center" }}>
                            {value.quantity}
                          </TableCell>
                          <TableCell colSpan={2} sx={{ textAlign: "right" }}>
                            {formatCurrency(
                              Number(value.price) * Number(value.quantity)
                            )}
                          </TableCell>
                          <TableCell colSpan={2} sx={{ textAlign: "center" }}>
                            <Button onClick={() => handleDeleteCart(value.id)}>
                              <Delete />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} sm={12} lg={4}>
                <Card variant="outlined" sx={{ width: "100%", maxWidth: 600 }}>
                  <CardContent sx={{ padding: 0 }}>
                    <Typography variant="h6" fontWeight="bold" padding={2}>
                      Ringkasan Belanja
                    </Typography>
                    <Divider />
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      paddingX={2}
                      paddingTop={1}
                    >
                      <Typography>Total Produk:</Typography>
                      <Typography fontWeight="bold">
                        {cartItems.length}
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      paddingX={2}
                      paddingY={1}
                    >
                      <Typography>Total Harga:</Typography>
                      <Typography fontWeight="bold">
                        {formatCurrency(
                          cartItems.reduce((total, cartItem) => {
                            const item = cartItems.find(
                              (i) => i.id === cartItem.id
                            );
                            return (
                              total +
                              (item?.price || 0) * Number(cartItem.quantity)
                            );
                          }, 0)
                        )}
                      </Typography>
                    </Box>
                    <Divider />
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      paddingX={2}
                      paddingTop={2}
                    >
                      <Button
                        variant="contained"
                        disableElevation
                        onClick={handleRedirectWa}
                      >
                        Beli
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )}
        </Stack>
      </Container>
    </Box>
  );
};
