import { BrowserRouter, Route, Routes } from "react-router-dom";
import FloatingWa from "../components/FloatingWa";
import Foot from "../components/Foot";
import Header from "../components/Header";
import Produk from "../components/Produk";
import ThemeComponent from "../components/ThemeComponent";
import { ShoppingCartProvider } from "../context/ShoppingCartContext";
import { Cart } from "../pages/Cart";
import DetailProduct from "../pages/DetailProduct";
import Home from "../pages/Home";
import NotFound from "../pages/NotFound";

export default function index() {
  return (
    <ShoppingCartProvider>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route
            path="/"
            element={
              <ThemeComponent>
                <Home />
              </ThemeComponent>
            }
          ></Route>
          <Route
            path="/produk"
            element={
              <ThemeComponent>
                <Produk />
              </ThemeComponent>
            }
          ></Route>
          <Route
            path="/produk/:id"
            element={
              <ThemeComponent>
                <DetailProduct />
              </ThemeComponent>
            }
          ></Route>
          <Route
            path="/cart"
            element={
              <ThemeComponent>
                <Cart />
              </ThemeComponent>
            }
          ></Route>
          <Route
            path="*"
            element={
              <ThemeComponent>
                <NotFound />
              </ThemeComponent>
            }
          ></Route>
        </Routes>
        <FloatingWa />
        <Foot />
      </BrowserRouter>
    </ShoppingCartProvider>
  );
}
