import { useQuery } from "@tanstack/react-query";
import { ParamsGetKategori, GetKategoriResponse } from "../../types/index";
import axios from "axios";
import { apiUrl } from "../../konstanta/common";
import qs from "query-string";

const handleRequest = async (params: ParamsGetKategori) => {
  const apiGetKategori = qs.stringifyUrl({
    url: `${apiUrl}/api/getkategori`,
    query: {
      ukmId: params.ukmId,
      search: `${params.search}`,
      size: params.size,
      page: params.page,
    },
  });
  const { data } = await axios.get<GetKategoriResponse>(apiGetKategori);
  return data.data;
};

export default function useKategoriNoAuth(params: ParamsGetKategori) {
  return useQuery<GetKategoriResponse["data"]>(
    ["kategori", params],
    () => handleRequest(params),
    {
      enabled: !!params.ukmId,
      refetchOnWindowFocus: false,
    }
  );
}
