import { Link, useLocation } from "react-router-dom";
import Badge, { BadgeProps } from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { UseShoppingCart } from "../context/ShoppingCartContext";
import React, { useState } from "react";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Container,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Home, Inventory, Menu } from "@mui/icons-material";

const drawerWidth = 240;
const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

const Header = () => {
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  const { cartItems } = UseShoppingCart();
  const [mobileOpen, setMobileOpen] = useState(false);

  const container =
    window !== undefined ? () => window.document.body : undefined;
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <List>
        <ListItem disablePadding>
          <ListItemButton selected={location.pathname === "/"} href={"/"}>
            <ListItemIcon>
              <Home />
            </ListItemIcon>
            <ListItemText primary={"Beranda"} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            selected={location.pathname === "/produk"}
            href={"/produk"}
          >
            <ListItemIcon>
              <Inventory />
            </ListItemIcon>
            <ListItemText primary={"Produk"} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            selected={location.pathname === "/cart"}
            href={"/cart"}
          >
            <ListItemIcon>
              <StyledBadge badgeContent={cartItems.length} color="secondary">
                <ShoppingCartIcon />
              </StyledBadge>
            </ListItemIcon>
            <ListItemText primary={"Keranjang"} />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );

  return (
    <React.Fragment>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="static"
          sx={{ backgroundColor: "#FA4C46", mr: { sm: `${drawerWidth}px` } }}
        >
          <Drawer
            anchor="right"
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={() => setMobileOpen(false)}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", lg: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
          <Toolbar>
            <Container maxWidth="lg">
              <Box display="flex" justifyContent="space-between" gap={2}>
                <Stack direction="row" spacing={2}>
                  <Link to={"/"}>
                    <Avatar
                      alt="Logo-Kikomart"
                      src={require("../assets/logo/kikomart-logo.png")}
                    />
                  </Link>
                  <Button color="inherit" href={"/"}>
                    Kikomart
                  </Button>
                </Stack>
                {mobileScreen ? (
                  <IconButton onClick={handleDrawerToggle} aria-label="cart">
                    <StyledBadge
                      badgeContent={cartItems.length}
                      color="secondary"
                    >
                      <Menu htmlColor="#fff" />
                    </StyledBadge>
                  </IconButton>
                ) : (
                  <Stack direction="row" spacing={2}>
                    <Button
                      variant={location.pathname === "/" ? "outlined" : "text"}
                      color="inherit"
                      href={"/"}
                    >
                      Beranda
                    </Button>
                    <Button
                      variant={
                        location.pathname === "/produk" ? "outlined" : "text"
                      }
                      color="inherit"
                      href={"/produk"}
                    >
                      Produk
                    </Button>
                    <IconButton href={"/cart"} aria-label="cart">
                      <StyledBadge
                        badgeContent={cartItems.length}
                        color="secondary"
                      >
                        <ShoppingCartIcon htmlColor="#fff" />
                      </StyledBadge>
                    </IconButton>
                  </Stack>
                )}
              </Box>
            </Container>
          </Toolbar>
        </AppBar>
      </Box>
    </React.Fragment>
  );
};
export default Header;
