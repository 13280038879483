import { Box } from "@mui/material";
import Banner from "../components/Banner";
import Favorit from "../components/Favorit";
import Hero from "../components/Hero";

function Home() {
  return (
    <Box>
      <Banner />
      <Favorit />
      <Hero />
    </Box>
  );
}

export default Home;
