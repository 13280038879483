import { useQuery } from "@tanstack/react-query";
import { GetItemDetailResponse } from "../../types/index";
import axios from "axios";
import { apiUrl } from "../../konstanta/common";

const handleRequest = async (itemId: number) => {
  const { data } = await axios.get<GetItemDetailResponse>(
    `${apiUrl}/api/item/detail/${itemId}`
  );
  return data.data;
};

export default function useItemDetailNoAuth(itemId: number) {
  return useQuery<GetItemDetailResponse["data"]>(
    ["itemDetail", itemId],
    () => handleRequest(itemId),
    {
      enabled: !!itemId,
      refetchOnWindowFocus: false,
    }
  );
}
