import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Chip,
  CircularProgress,
  Container,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import useItemNoAuth from "../services/queries/useItemNoAuth";
import { formatCurrency } from "../utilities/formatCurrency";

const Favorit = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const initialParamsItem = {
    ukmId: 1,
    size: 6,
    page: 1,
    search: "",
    kategori: "",
  };
  const { data: item, isLoading } = useItemNoAuth(initialParamsItem);

  const handleGoToDetail = (id: number) => {
    navigate(`produk/${id}`);
  };

  return (
    <Container maxWidth="lg" sx={{ paddingX: 4 }}>
      <Stack justifyContent="center" marginY={4} spacing={4}>
        <Typography
          variant="h4"
          textAlign="center"
          fontWeight="bold"
          color="primary"
        >
          Produk
        </Typography>
        {isLoading ? (
          <div className="w-full h-screen py-32 mx-auto text-center">
            <CircularProgress color="secondary" size={96} />
          </div>
        ) : (
          <div className="">
            {item && item.totalElements > 0 ? (
              <Box
                display="grid"
                gridTemplateColumns="repeat(auto-fill, minmax(300px, 1fr))"
                gap={3}
              >
                {item.content.map((value) => (
                  <Card key={String(value.id)} variant="outlined">
                    <CardActionArea onClick={() => handleGoToDetail(value.id)}>
                      <CardMedia
                        component="img"
                        sx={{ height: 151 }}
                        image={
                          value.gambar
                            ? value.gambar
                            : require("../assets/logo/kikomart-logo-blue.jpg")
                        }
                        alt="Toko - Icon"
                      />
                      <CardContent>
                        <Chip
                          label={value.kategori.nama}
                          variant="outlined"
                          size="small"
                          sx={{
                            color: "#540DEF",
                            borderColor: "#540DEF",
                          }}
                        />
                        <Typography
                          gutterBottom
                          variant="body2"
                          component="div"
                          marginTop={1}
                        >
                          {value.namaBarang}
                        </Typography>
                        <Typography
                          variant="h6"
                          fontWeight="bold"
                          align="right"
                        >
                          {formatCurrency(Number(value.multipleHarga[0].harga))}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                ))}
              </Box>
            ) : (
              <Box
                display="flex"
                flex={1}
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height={
                  isMobile ? "calc(100vh - 248px)" : "calc(100vh - 264px)"
                }
              >
                <Typography variant="h6" fontWeight="bold">
                  Produk tidak ditemukan
                </Typography>
              </Box>
            )}
          </div>
        )}
      </Stack>
    </Container>
  );
};

export default Favorit;
