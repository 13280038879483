import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { idID } from "@mui/material/locale";
import CssBaseline from "@mui/material/CssBaseline";

interface IThemeComponentProps {
  children: React.ReactNode;
}

declare module "@mui/material/styles" {
  interface Palette {
    abu: Palette["primary"];
    ulo: Palette["primary"];
    whatsapp: Palette["primary"];
    bolddanger: Palette["primary"];
    greyc4: Palette["primary"];
    lightblue: Palette["primary"];
    buttonblue: Palette["primary"];
    buttonyellow: Palette["primary"];
    buttonred: Palette["primary"];
    buttongreen: Palette["primary"];
    buttonulo: Palette["primary"];
    lightwarning: Palette["primary"];
    lighterror: Palette["primary"];
    violet: Palette["primary"];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    abu?: PaletteOptions["primary"];
    ulo?: PaletteOptions["primary"];
    whatsapp?: PaletteOptions["primary"];
    bolddanger?: PaletteOptions["primary"];
    greyc4?: PaletteOptions["primary"];
    lightblue?: PaletteOptions["primary"];
    buttonblue?: PaletteOptions["primary"];
    buttonyellow?: PaletteOptions["primary"];
    buttonred?: PaletteOptions["primary"];
    buttongreen?: PaletteOptions["primary"];
    buttonulo?: PaletteOptions["primary"];
    lightwarning?: PaletteOptions["primary"];
    lighterror?: PaletteOptions["primary"];
    violet?: PaletteOptions["primary"];
  }
}

// Update the Button's color prop options
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    abu: true;
    ulo: true;
    whatsapp: true;
    bolddanger: true;
    greyc4: true;
    lightblue: true;
    buttonblue: true;
    buttonyellow: true;
    buttonred: true;
    buttongreen: true;
    buttonulo: true;
    lightwarning: true;
    lighterror: true;
    violet: true;
  }
}

const ThemeComponent = ({ children }: IThemeComponentProps) => {
  const theme = createTheme(
    {
      palette: {
        primary: {
          main: "#FA4C46",
          contrastText: "#fff",
        },
        secondary: {
          main: "#2D5078",
        },
        abu: {
          main: "#fff",
        },
        ulo: {
          main: "#45A779",
          contrastText: "#fff",
        },
        whatsapp: {
          main: "#399F3A",
          contrastText: "#fff",
        },
        bolddanger: {
          main: "#E52828",
        },
        greyc4: {
          main: "#C4C4C4",
          contrastText: "#fff",
        },
        lightblue: {
          main: "#1976D2",
        },
        buttonblue: {
          main: "#40A0F9",
          contrastText: "#fff",
        },
        buttonyellow: {
          main: "#FFC46C",
          contrastText: "#fff",
        },
        buttonred: {
          main: "#FF7373",
          contrastText: "#fff",
        },
        buttongreen: {
          main: "#61C15F",
          contrastText: "#fff",
        },
        buttonulo: {
          main: "#45a779 ",
          contrastText: "#fff",
        },
        lightwarning: {
          main: "#ED6C02",
          contrastText: "#fff",
        },
        lighterror: {
          main: "#D32F2F",
          contrastText: "#fff",
        },
        violet: {
          main: "#540DEF",
          contrastText: "#fff",
        },
      },
      typography: {
        fontFamily: "'Open Sans', sans-serif",
        fontWeightMedium: 500,
        fontWeightBold: 700,
      },
    },
    idID
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default ThemeComponent;
