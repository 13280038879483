import { Link, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";

const Foot = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const thisYear = new Date().getFullYear();

  return (
    <Box
      display="flex"
      justifyContent="center"
      gap={1}
      sx={{
        background: "#2D5078",
        paddingY: isMobile ? 2 : 2.5,
      }}
    >
      <Link
        target="_blank"
        href="https://nore.web.id/"
        underline="hover"
        display="inline"
        color="#fff"
      >
        <Typography
          color="#fff"
          fontWeight="bold"
        >{`Nore Inovasi © ${thisYear}`}</Typography>
      </Link>
    </Box>
  );
};

export default Foot;
