import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Pagination,
  Stack,
  Container,
  TextField,
  InputAdornment,
  IconButton,
  Box,
  Chip,
  useMediaQuery,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ParamsGetItem } from "../types";
import { formatCurrency } from "../utilities/formatCurrency";
import CircularProgress from "@mui/material/CircularProgress";
import useItemNoAuth from "../services/queries/useItemNoAuth";
import { Search } from "@mui/icons-material";
import useKategoriNoAuth from "../services/queries/useKategoriNoAuth";

function Produk() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const [searchBox, setSearchBox] = useState<string>("");
  const [selectedKategori, setSelectedKategori] = useState("");

  const initialParamsItem = {
    ukmId: 1,
    size: 12,
    page: localStorage.getItem("productPage")
      ? Number(localStorage.getItem("productPage"))
      : 1,
    search: "",
    kategori: "",
  };

  const [paramsItem, setParamsItem] =
    useState<ParamsGetItem>(initialParamsItem);
  const { data: item, isLoading: isLoadingItem } = useItemNoAuth(paramsItem);
  const initialParamsKategori = {
    ukmId: 1,
    size: 500,
    page: 1,
    search: "",
  };
  const { data: kategori, isLoading: isLoadingKategori } = useKategoriNoAuth(
    initialParamsKategori
  );

  const handleSearchProduk = (text: string) => {
    setParamsItem((prev) => ({
      ...prev,
      search: text,
    }));
  };
  const handleSearchBox = (e: any) => {
    setSearchBox(e.target.value);
  };

  const handleGoToDetail = (id: number) => {
    navigate(`${id}`);
  };

  useEffect(() => {
    if (selectedKategori !== null) {
      setParamsItem((prev) => ({
        ...prev,
        kategori: selectedKategori,
      }));
    } else {
      setParamsItem((prev) => ({
        ...prev,
        kategori: "",
      }));
    }
  }, [selectedKategori]);

  useEffect(() => {
    localStorage.setItem("productPage", String(paramsItem.page));
  }, [paramsItem.page]);

  return (
    <Container maxWidth="lg">
      <Stack marginY={4} spacing={4}>
        <Stack direction="row" spacing={1}>
          <TextField
            label="Cari"
            value={searchBox}
            onChange={handleSearchBox}
            size={"small"}
            onKeyPress={(e) => {
              const element = e.target as HTMLInputElement;
              if (e.key === "Enter") {
                setParamsItem((prev) => ({
                  ...prev,
                  search: element.value,
                  page: 1,
                }));
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => handleSearchProduk(searchBox)}>
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <FormControl
            size="small"
            sx={{
              width: 300,
            }}
          >
            <InputLabel id="demo-simple-select-helper-label">
              Kategori
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              label="Kategori"
              defaultValue=""
              onChange={(e) => setSelectedKategori(String(e.target.value))}
            >
              <MenuItem value="">Semua</MenuItem>
              {kategori &&
                kategori?.content.map((k) => (
                  <MenuItem key={k.id} value={k.id}>
                    {k.nama}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Stack>

        {isLoadingItem || isLoadingKategori ? (
          <div className="w-full h-screen py-32 mx-auto text-center">
            <CircularProgress color="secondary" size={96} />
          </div>
        ) : (
          <div className="">
            {item && item.totalElements > 0 ? (
              <Box
                display="grid"
                gridTemplateColumns="repeat(auto-fill, minmax(250px, 1fr))"
                gap={3}
              >
                {item.content.map((value) => (
                  <Card key={String(value.id)} variant="outlined">
                    <CardActionArea onClick={() => handleGoToDetail(value.id)}>
                      <CardMedia
                        component="img"
                        sx={{ height: 151 }}
                        image={
                          value.gambar
                            ? value.gambar
                            : require("../assets/logo/kikomart-logo-blue.jpg")
                        }
                        alt="Toko - Icon"
                      />
                      <CardContent>
                        <Chip
                          label={value.kategori.nama}
                          variant="outlined"
                          size="small"
                          sx={{
                            color: "#540DEF",
                            borderColor: "#540DEF",
                          }}
                        />
                        <Typography
                          gutterBottom
                          variant="body2"
                          component="div"
                          marginTop={1}
                        >
                          {value.namaBarang}
                        </Typography>
                        <Typography
                          variant="h6"
                          fontWeight="bold"
                          align="right"
                        >
                          {formatCurrency(Number(value.multipleHarga[0].harga))}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                ))}
              </Box>
            ) : (
              <Box
                display="flex"
                flex={1}
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height={
                  isMobile ? "calc(100vh - 248px)" : "calc(100vh - 264px)"
                }
              >
                <Typography variant="h6" fontWeight="bold">
                  Produk tidak ditemukan
                </Typography>
              </Box>
            )}
          </div>
        )}
        {/* Produk */}
        {/* End Produk */}
        {item && item.totalElements > 0 && (
          <Stack direction="row" spacing={3} justifyContent="flex-end">
            <Pagination
              color="primary"
              shape="rounded"
              count={Math.ceil(
                Number(item?.totalElements) / Number(initialParamsItem.size)
              )}
              page={paramsItem.page}
              onChange={(event, value) => {
                setParamsItem((prev) => ({
                  ...prev,
                  page: value,
                }));
              }}
            />
          </Stack>
        )}
      </Stack>
    </Container>
  );
}

export default Produk;
