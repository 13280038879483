import { useQuery } from "@tanstack/react-query";
import { ParamsGetItem, GetItemResponse } from "../../types/index";
import axios from "axios";
import { apiUrl } from "../../konstanta/common";
import qs from "query-string";

const handleRequest = async (params: ParamsGetItem) => {
  const apiGetItem = qs.stringifyUrl({
    url: `${apiUrl}/api/getitem`,
    query: {
      ukmId: params.ukmId,
      search: `${params.search}`,
      kategori: params.kategori,
      size: params.size,
      page: params.page,
    },
  });
  const { data } = await axios.get<GetItemResponse>(apiGetItem);
  return data.data;
};

export default function useItemNoAuth(params: ParamsGetItem) {
  return useQuery<GetItemResponse["data"]>(
    ["item", params],
    () => handleRequest(params),
    {
      enabled: !!params?.ukmId,
      refetchOnWindowFocus: false,
    }
  );
}
