import { useParams } from "react-router-dom";
import { UseShoppingCart } from "../context/ShoppingCartContext";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import {
  Avatar,
  Button,
  Chip,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Swal from "sweetalert2";
import { formatCurrency } from "../utilities/formatCurrency";
import useItemDetailNoAuth from "../services/queries/useItemDetailNoAuth";
// import useNoWaAdmin from "../services/queries/useNoWaAdmin";
import { Add, ChevronLeft, ChevronRight, Remove } from "@mui/icons-material";

function DetailProduct() {
  const { id } = useParams();
  const { handleAddToCart, qty, setQty } = UseShoppingCart();
  const theme = useTheme();
  const isPhonescreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isTabletscreen = useMediaQuery(theme.breakpoints.down("md"));

  const { data: itemDetail, isLoading: isLoadingDetail } = useItemDetailNoAuth(
    Number(id)
  );
  // const { data: noWaAdmin } = useNoWaAdmin();

  //Checkout WA
  const handleRedirectWa = () => {
    window.open(
      `https://api.whatsapp.com/send?phone=628112933188&text=Hallo,%20saya%20ingin%20order%3A%0A%0A${itemDetail?.content[0].namaBarang}%20(x${qty})`,
      "_blank"
    );
  };
  // Add barang to Cart
  const handleClickedAdd = () => {
    handleAddToCart({
      id: itemDetail?.content[0].id,
      name: itemDetail?.content[0].namaBarang,
      price: Number(itemDetail?.content[0].harga[0].harga),
      quantity: qty,
    });
    Swal.fire("Selamat", "Produk telah ditambahkan di keranjang", "success");

    setQty(1);
  };
  // Handle Increment Input Barang
  const handleIncrement = () => {
    if (Number(itemDetail?.content[0].stok) > Number(qty)) {
      setQty(Number(qty) + 1);
    }
  };
  // Handle Decrement Input Barang
  const handleDecrement = () => {
    if (Number(qty) > 1) {
      setQty(Number(qty) - 1);
    }
  };
  // Handle Input Barang
  const handleChange = (e: any) => {
    setQty(Number(e.target.value));
    // }
  };

  if (isLoadingDetail) {
    return (
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <CircularProgress
          color="secondary"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      </Box>
    );
  }

  if (itemDetail && itemDetail?.content.length <= 0) {
    return (
      <Box sx={{ display: "flex" }}>
        <Typography>Produk tidak ditemukan</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ height: isPhonescreen ? undefined : "calc(100vh - 128px)" }}>
      <Box display="flex" justifyContent="center" padding={3}>
        <Box display="flex" flexDirection="column" gap={2}>
          <Box display="flex" justifyContent="space-between">
            <Button
              startIcon={<ChevronLeft />}
              href={"/produk"}
              sx={{ textTransform: "none" }}
            >
              Ke Produk
            </Button>
            <Button
              endIcon={<ChevronRight />}
              href={"/cart"}
              sx={{ textTransform: "none" }}
            >
              Ke Keranjang
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: isPhonescreen ? "column" : "row",
              border: "1px solid rgba(0,0,0,0.12)",
              borderRadius: "9px",
              maxWidth: isPhonescreen ? "301px" : "800px",
            }}
          >
            <Box sx={{ width: 300 }}>
              <Avatar
                src={
                  itemDetail?.content[0].gambar
                    ? itemDetail?.content[0].gambar
                    : require("../assets/logo/kikomart-logo-blue.jpg")
                }
                alt="Item"
                sx={{
                  width: "100%",
                  height: "100%",
                  borderRadius: isPhonescreen ? "9px 9px 0 0 " : "9px 0 0 9px",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                gap: 1,
                padding: 3,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Box>
                  <Chip
                    label={itemDetail?.content[0].kategori.nama}
                    variant="outlined"
                    size="small"
                    sx={{
                      color: "#540DEF",
                      borderColor: "#540DEF",
                    }}
                  />
                </Box>
                <Typography>{itemDetail?.content[0].namaBarang}</Typography>
                <h3 className="text-2xl font-bold">
                  {formatCurrency(
                    Number(itemDetail?.content[0].harga[0].harga)
                  )}
                </h3>
                <p>{itemDetail?.content[0].deskripsi}</p>

                {/* Input Button Quantity */}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Box>
                  <Box display="flex" alignItems="center" gap={1}>
                    <Button
                      onClick={handleDecrement}
                      sx={{
                        minWidth: "unset",
                        padding: "6px",
                        width: "44px",
                        height: "44px",
                      }}
                    >
                      <Remove />
                    </Button>
                    <TextField
                      variant="outlined"
                      size="small"
                      type="number"
                      value={qty}
                      onChange={handleChange}
                      InputProps={{
                        inputProps: {
                          min: 1,
                          max: itemDetail?.content[0].stok,
                          style: {
                            textAlign: "center",
                          },
                        },
                      }}
                    />
                    <Button
                      onClick={handleIncrement}
                      sx={{
                        minWidth: "unset",
                        padding: "6px",
                        width: "44px",
                        height: "44px",
                      }}
                    >
                      <Add />
                    </Button>
                    {/* Stok barang */}
                    <p className="flex inline-flex mx-2 text-sm opacity-50">
                      tersisa {"" + itemDetail?.content[0].stok} buah
                    </p>
                  </Box>
                </Box>
                <Box display="flex" justifyContent="flex-end">
                  {/* pesan apabila quantity barang lebih dari stock */}
                  <Typography
                    variant="body2"
                    color="error"
                    visibility={
                      Number(qty) > Number(itemDetail?.content[0].stok)
                        ? undefined
                        : "hidden"
                    }
                    textAlign="right"
                  >
                    Jumlah pesanan melebihi stok
                  </Typography>
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection={isTabletscreen ? "column" : "row"}
                justifyContent="flex-end"
                gap={1}
              >
                <Button
                  disabled={
                    Number(qty) === 0 ||
                    Number(qty) > Number(itemDetail?.content[0].stok)
                  }
                  variant="contained"
                  disableElevation
                  onClick={() => handleClickedAdd()}
                >
                  + Keranjang
                </Button>
                <Button
                  disabled={
                    Number(qty) === 0 ||
                    Number(qty) > Number(itemDetail?.content[0].stok)
                  }
                  variant="outlined"
                  onClick={handleRedirectWa}
                >
                  Beli Sekarang
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default DetailProduct;
