import { Avatar, Box, Button, Stack, Typography } from "@mui/material";
import { Container } from "@mui/system";

function Hero() {
  return (
    <div className="max-h-full mt-4 hero bg-base-200">
      <Container>
        <div className="text-center hero-content">
          <Stack paddingY={3} spacing={2}>
            <Avatar
              alt="Logo-Kikomart"
              src={require("../assets/logo/kikomart-logo.png")}
              className="mx-auto sm:w-64 md:w-72 "
              sx={{ width: 180, height: 180 }}
            />
            <Typography variant="h5" fontWeight="bold">
              Kikomart
            </Typography>
            <Box maxWidth={500}>
              <Typography fontWeight="regular">
                Toko bahan kue dan bahan masakan yang pastinya lengkap, murah,
                dan berkualitas. Beralamat di Ruko Peterongan Plaza A 11.
              </Typography>
            </Box>
            <Box>
              <Button variant="contained" disableElevation href="/produk">
                Lihat Produk
              </Button>
            </Box>
          </Stack>
        </div>
      </Container>
    </div>
  );
}

export default Hero;
