import { Box, Button, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";

const NotFound = () => {
  const theme = useTheme();
  const isPhoneScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height={isPhoneScreen ? "calc(100vh - 112px)" : "calc(100vh - 128px)"}
      gap="24px"
    >
      <h1 className="font-extrabold tracking-widest text-slate-600 text-9xl">
        404
      </h1>
      <div className="absolute px-2 text-sm rounded bg-red-200 rotate-12">
        Halaman Tidak Ditemukan
      </div>
      <Button variant="contained" disableElevation href="/">
        Ke Beranda
      </Button>
    </Box>
  );
};

export default NotFound;
