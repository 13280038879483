// import { Box } from "@mui/material";
import FloatingWhatsApp from "react-floating-whatsapp";
// import useNoWaAdmin from "../services/queries/useNoWaAdmin";

const FloatingWa = () => {
  // const { data: noWaAdmin } = useNoWaAdmin();

  return (
    <div>
      <FloatingWhatsApp
        // phoneNumber={String(noWaAdmin?.nomor)}
        phoneNumber="628112933188"
        allowClickAway
        accountName="Admin"
        avatar={require("../assets/logo/kikomart-logo-blue.jpg")}
        statusMessage="Online"
        chatMessage={`Halo, ada yang dapat kami bantu?`}
        styles={{
          zIndex: 1,
        }}
      />
    </div>
  );
};

export default FloatingWa;
